#connectModal {
  height: 100%;
  .top-block {
    position: relative;
    height: 80px;
    width: 100%;
    border-radius: 3px 3px 0px 0px;
    background-color: #34d399;
    .title {
      color: white;
    }
  }

  .bottom-block {
    background-color: white;
    border-radius: 0px 0px 3px 3px;
  }

  .cancel-btn {
    border: 0px;
    background-color: #abb1b9;
    color: white;
    border-radius: 5px;
    padding: 5px 10px;
  }

  .confirm-btn {
    border: 0px;
    background-color: #11ba81;
    color: white;
    border-radius: 5px;
    padding: 5px 10px;
  }

  .information-icon-block {
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    margin-top: -40px;
  }

  .information-icon {
    width: min-content;
    background-color: #34d399;
    margin: 0 auto;
    padding: 8px;
  }
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
@font-face {
  font-family: "Quasimoda";
  font-weight: lighter;
  src: url("./assets/fonts/Quasimoda-Font/quasimoda-light.otf");
}
@font-face {
  font-family: "Quasimoda";
  font-weight: normal;
  src: url("./assets/fonts/Quasimoda-Font/Quasimoda Medium.ttf");
}
@font-face {
  font-family: "Trade Gothic";
  font-weight: bold;
  src: url("./assets/fonts/Trade-Gothic-Font/Trade Gothic LT Bold.ttf");
}

body {
  margin: 0;
  background-color: #f8f8f8 !important;
  background-image: url(./assets/img/background-patern.svg);
  font-family: "Quasimoda", sans-serif;
  font-weight: normal;
  background-size: 100%;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Trade Gothic", sans-serif;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.sms-inp svg path {
  fill: #b2d8d2;
}

.accordion-item {
  border: 1px solid #212529;
}

.accordion-body {
  background-color: #212529;
}

.accordion-button:not(.collapsed) {
  color: #212529;
  background-color: #fff;
  box-shadow: inset 0 -1px 0 rgb(0 0 0 / 13%);
}

.accordion-button:focus {
  border: none;
  box-shadow: 0 0 0 0;
}

#loginPage-div {
  background-color: #ffffff;
  .login-container {
    min-height: 100vh;
  }

  .main-block {
    height: 60vh;
    width: 60vw;
    max-height: 480px;
    max-width: 1024px;
  }

  .login-block {
    height: 100%;
    margin-left: -1px;
    border-radius: 0 20px 20px 0;
  }

  .logo-block {
    background-color: white;
    border-radius: 20px 0 0 20px;
  }

  .logo-block img {
    width: 70%;
  }

  .login-component {
    height: 100%;
  }

  @media only screen and (max-width: 992px) {
    .main-block {
      min-width: 320px;
      max-height: 480px;
    }

    .login-block {
      border-radius: 20px;
    }

    .logo-img {
      left: calc(50% - 68px);
      width: 136px;
      border-radius: 30px;
      position: absolute;
      top: 5%;
    }

    .login-component {
      height: 80%;
    }
  }

  @media only screen and (max-width: 576px) {
    .main-block {
      height: auto;
      min-width: 90%;
    }

    .login-component {
      height: 80%;
    }

    .login-btn-block {
      padding-top: 5%;
    }
  }
}

#products-card {
  width: 350px;
  min-width: 350px;
  max-width: 350px;
  height: 440px;
  min-height: 440px;
  max-height: 440px;

  .image-block {
    img {
      max-width: 100%;
    }
  }

  .content-block {
    flex: 1;
  }

  .btn-redeeem {
    max-height: 45px;
    white-space: nowrap;
  }
}

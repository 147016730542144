#stores {
  .paging-dot {
    width: 20px;
    height: 20px;
  }

  .paging-dot > circle {
    r: 6px;
    cx: 10px;
    cy: 10px;
  }

  .carousel-block {
    height: 500px;
    margin: 0 auto;
  }
}

#homeOverview {
  .cardBox {
    &.storecard {
      .cardBox-head {
        &.terugkerend {
          background-color: #ffc781;
        }
        &.new {
          background-color: #fea0ac;
        }
        &.addmember {
          min-height: auto;
        }
        padding-top: 1.5rem;
        padding-bottom: 1.5rem;
        display: block;
        background-color: #b2d8d2;
        ul {
          max-width: 310px;
          padding-right: 10px;
          padding-left: 10px;
        }
        h1 {
          font-size: 33px;
          text-align: center;
        }
        .cardBox-head__alert {
          max-width: 340px;
          margin-right: auto;
          margin-left: auto;
          h2 {
            font-size: 23px;
          }
          h5 {
            font-size: 17px;
          }
        }
      }
      .cardBox-body {
        button {
          height: 65px;
          @media screen and (max-width: 375px) {
            font-size: 12px;
          }
        }
      }
      &.addpoints {
        ul {
          max-width: 230px;
        }
      }
    }

    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.16);
    width: calc(100% - 25px);
    max-width: 400px;
    margin: auto;
    border-radius: 15px;
    &-head {
      width: 100%;
      border-radius: 15px 15px 0px 0px;
      min-height: 200px;
      display: flex;
      justify-content: center;
      align-items: center;
      h1 {
        font-size: 17px;
        font-weight: bold;
      }
      background: #f7f8fa;
    }
    &-body {
      border-radius: 0px 0px 15px 15px;
      background-color: #fff;
      ul {
        li {
          text-align: center;
          font-size: 12px;
          font-weight: bold;
          .shape {
            display: block;
            width: 23px;
            height: 23px;
            border-radius: 50%;
            margin: auto;
            margin-bottom: 4px;
          }
          &.herkend .shape {
            background-color: #b2d8d2;
          }
          &.terugkerend .shape {
            background-color: #ffc781;
          }
          &.nieuw .shape {
            background-color: #fea0ac;
          }
        }
      }
      &__btn {
        text-align: center;
        .btn-main-bg {
          background-color: #1b1077 !important;
          color: #fff !important;
          border-radius: 8px !important;
          height: 49px;
          text-transform: uppercase;
          width: 310px;
        }

        .w-full {
          height: 50px !important;
          width: 100%;
          max-width: 310px;
          margin-left: auto;
          margin-right: auto;
          @media screen and (max-width: 375px) {
            max-width: calc(100% - 40px);
          }
        }
      }
      &__btns {
        text-align: center;
        width: 150px;

        .btn-main-bg {
          background-color: #1b1077 !important;
          color: #fff !important;
          border-radius: 8px !important;
          height: 66px;
          text-transform: uppercase;
          width: 150px;
          &:first-child {
            margin-right: 8px;
          }
        }

        .w-full {
          height: 50px !important;
          width: 100%;
          max-width: 310px;
          margin-left: auto;
          margin-right: auto;
          @media screen and (max-width: 375px) {
            max-width: calc(100% - 40px);
          }
        }
      }
      .send-sms {
        max-width: 310px;
        margin: auto;
      }

      .btn-points-block {
        margin: auto;
        max-width: 310px;
        width: 100%;
        display: flex;
        justify-content: space-between;
        height: 49px;
      }

      .btn-points-block button {
        color: white;
        background-color: #1b1077;
        width: 48%;
      }

      .btn-points-block button:disabled {
        background-color: lightgray;
      }
    }

    .cardBox-body__search {
      margin: auto;
      max-width: 310px;
    }
  }

  min-height: 130vh;
}

#registerPage-div {
  padding-top: 1rem;
  background-color: #ffffff;
  .login-container {
    min-height: 100vh;
  }

  .logo-img-block-sm {
    margin: 2rem 0;
    height: 40px;
    .logo-img {
      width: 136px;
      position: relative;
    }
  }

  .main-block {
    height: 60vh;
    width: 60vw;
    min-height: 565px;
    max-height: 680px;
    max-width: 1024px;
  }

  .login-block {
    height: 100%;
    margin-left: -1px;
    border-radius: 0 20px 20px 0;
  }

  .logo-block {
    background-color: white;
    border-radius: 20px 0 0 20px;
  }

  .logo-block img {
    width: 70%;
  }

  .login-component {
    height: 100%;
  }

  @media only screen and (max-width: 992px) {
    .main-block {
      min-width: 320px;
      min-height: 582px;
      max-height: 680px;
    }

    .login-block {
      border-radius: 20px;
    }

    .logo-img {
      left: calc(50% - 68px);
      width: 136px;
      border-radius: 30px;
      position: absolute;
      top: 2%;
    }

    .login-component {
      height: 80%;
    }
  }

  @media only screen and (max-width: 576px) {
    .login-container {
      min-height: auto;
    }

    .main-block {
      height: auto;
      min-width: 80%;
    }

    .login-component {
      height: 80%;
    }

    .login-btn-block {
      padding-top: 5%;
    }
  }
}

#login-form {
  height: 100%;
  background-color: #f3f4f7;
  a {
    font-size: 0.8rem;
  }

  .form-label {
    margin: 0;
  }

  .input-group-text {
    background-color: white;
  }

  .form-label {
    font-size: 0.6rem;
  }
  .form-control {
    border-left: 0;
  }
  .input-group-text i {
    color: #abb1b9;
    width: 18px;
  }

  .password-block {
    margin-top: 10px;
  }

  .login-btn-block {
    text-align: center;
    .login-btn {
      width: 100%;
      background-color: #1e3faf;
    }

    .forgot-btn {
      font-size: 0.6rem;
    }
  }
}

#changeAccountModal {
  height: 100%;

  .form-label {
    margin: 0;
  }

  .input-group-text {
    background-color: white;
  }

  .form-label {
    font-size: 0.6rem;
  }
  .form-control {
    border-left: 0;
  }
  .input-group-text i {
    color: #abb1b9;
    width: 18px;
  }
}

#home {
  background-color: #ffffff;
  padding-top: 8rem !important;
  .welcome-block {
    margin-bottom: 20px;
  }

  #bg-image {
    height: 20rem;
    background-color: #ffffff;
    background-image: url("/assets/points-bg.png");
    background-position: bottom;
    background-repeat: space;

    .points-block {
      height: 100%;
      .points-p {
        margin-top: 3%;
        font-size: 6rem;
      }
    }
  }

  #wallet-block {
    height: 10rem;
  }

  .text-light-shadow {
    color: aliceblue;
    text-shadow: 2px 2px 4px rgb(0 0 0 / 50%);
  }

  #wallet-block {
    background-color: #f8f8f8;
    width: 100%;
    height: 10rem;

    .wallet-div {
      img {
        background-color: white;
        padding: 20px 15px;
        border-radius: 15px;
        box-shadow: 0px 0px 5px 2px rgb(0 0 0 / 20%);
      }
    }
  }

  .parallax {
    use {
      animation: move-forever 25s cubic-bezier(0.55, 0.5, 0.45, 0.5) infinite;
    }
    use:nth-child(1) {
      animation-delay: -2s;
      animation-duration: 7s;
    }
    use:nth-child(2) {
      animation-delay: -3s;
      animation-duration: 10s;
    }
    use:nth-child(3) {
      animation-delay: -4s;
      animation-duration: 13s;
    }
    use:nth-child(4) {
      animation-delay: -5s;
      animation-duration: 20s;
    }
  }
  @keyframes move-forever {
    0% {
      transform: translate3d(-90px, 0, 0);
    }
    100% {
      transform: translate3d(85px, 0, 0);
    }
  }

  .waves {
    position: relative;
    width: 100%;
    height: 15vh;
    margin-bottom: -7px; /*Fix for safari gap*/
    min-height: 100px;
    max-height: 150px;
  }
  /*Shrinking for mobile*/
  @media (max-width: 768px) {
    .waves {
      height: 40px;
      min-height: 40px;
    }
  }
}

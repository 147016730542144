#members-navbar {
  .menu-btn {
    font-size: 2rem;
    padding: 0;
    color: black !important;
    border: 0px !important;
  }

  .navbar-toggler:focus,
  .navbar-toggler:active,
  .navbar-toggler-icon:focus {
    outline: none;
    box-shadow: none;
  }
}

#membersNavbar-offcanvasNavbar {
  background-color: #202428;
  .navbar-item {
    text-decoration: none;
    margin-bottom: 1.5rem;
    cursor: pointer;
  }

  .btn-admin {
    width: 80%;
    margin: 0 auto;
    bottom: 5%;
    position: absolute;
  }
}
